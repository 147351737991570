import request from "../../utils/request";

/**获取用户列表总数
 * @param {object} params
 * @param {string} params.nickName  -昵称   非必填
 * @param {string} params.phone     -电话   非必填
 * @param {int} params.unitId       -单位ID 必填
 */
export function getCount(params) {
  return request("/user/getCount", {
    method: "get",
    params: params
  });
}

/**获取用户列表总数
 * @param {object} params
 * @param {string} params.nickName  -昵称   非必填
 * @param {string} params.phone     -电话   非必填
 * @param {int} params.unitId       -单位ID 必填
 * @param {int} params.pageIndex    -页索引 必填
 * @param {int} params.pageSize     -页容量 必填
 */
export function getList(params) {
    return request("/user/getList", {
      method: "get",
      params: params
    });
  }

 /**新建用户
 * @param {object} params
 * @param {string} params.certId    -身份证(18)
 * @param {string} params.name      -用户姓名 必填
 * @param {int} params.phone        -电话 必填
 * @param {int} params.unitId       -单位ID 必填
 * @param {int} params.userName     -用户名 必填
 */
export function createUser(params) {
    return request("/user/postCreateUser", {
      method: "post",
      params: params
    });
  }

/**密码修改（新旧替换）
 * @param {object} params
 * @param {string} params.newPsw    -新密码(18)
 * @param {string} params.oldPsw    -旧密码 必填
 * @param {int} params.unitId       -单位ID 必填
 * @param {int} params.userId       -用户ID 必填
 * @param {string} params.userName     -用户名 必填
 */
export function modifyPsw(params) {
    return request("/user/postModifyPsw", {
      method: "post",
      params: params
    });
  }


/**忘记密码
 * @param {object} params
 * @param {string} params.newPsw       -新密码(18)
 * @param {int} params.unitId          -单位ID 必填
 * @param {int} params.userId          -用户ID 必填
 * @param {string} params.userName     -用户名 必填
 * @param {string} params.verifyCode   -验证码 必填
 */
export function postModifyPswByMsg(params) {
    return request("/user/postModifyPswByMsg", {
      method: "post",
      params: params
    });
  } 
  
/**用户登录
 * @param {object} params
 * @param {string} params.password     -密码(18)
 * @param {string} params.userName     -用户名 必填
 */
export function postUserLogin(params) {
    return request("/auth/postUserLogin", {
      method: "post",
      params: params
    });
  }  

/**发送验证码
 * @param {object} params
 * @param {string} params.phone     -电话
 */
export function sendVerify(params) {
    return request("/user/sendVerify", {
      method: "get",
      params: params
    });
  }    
  
/**注销用户
 * @param {object} params
 * @param {string} params.unitId      -单位id
 * @param {string} params.userId      -用户id
 */
export function DelUser(params) {
  return request("/user/postDelUser", {
    method: "post",
    params: params
  });
}    

/**获取菜单字典 */
export function getInfo() {
  return request("/menu/getInfo", {
    method: "get"
  });
}    


/**添加权限
 * @param {string} params.menuIdList     -菜单列表(数组)
 * @param {string} params.userId         -用户id 
 */
export function addUserMenu(params) {
  return request("/menu/addUserMenu", {
    method: "post",
    params: params
  });
}    


/**删除权限
 * @param {string} params.menuIdList     -菜单列表(数组)
 * @param {string} params.userId         -用户id 
 */
export function cancelUserMenu(params) {
  return request("/menu/cancelUserMenu", {
    method: "post",
    params: params
  });
}  

/**获取用户个人菜单
 * @param {string} params.userId         -用户id 
 */
export function getUserMenu(params) {
  return request("/menu/getUserMenu", {
    method: "get",
    params: params
  });
}  

/**获取图形验证码
 * @param {string} params.phone         -电话
 * @param {string} params.userName         -用户名
 */
export function getVerifyPic(params) {
  return request("/user/getVerifyPic", {
    method: "get",
    params: params
  });
}  