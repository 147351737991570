import { getturnCount, getTodayIntegration, getApplyByVipCount, getApplyByAbnormalCount, getFurtureIntegrationByDay } from "@/service/basePage/index";
import { PhotoAbnormalListCount, getLocalTrainAbnormalListCount2 } from "@/service/charge/index";
import { mapGetters } from 'vuex';
import ModifyPassword from '@/components/modifyPassword/index.vue'

export default {
    name: "homePage",
    components:{ModifyPassword},
    data() {
        return {
            homePage: {
                list: {
                    query: {
                        unitId: sessionStorage.getItem('unitId'),
                        stage: -1,
                        vehType: " ",
                    },
                    total: 0,
                    results: []
                },
                statis: {
                    todayTurnCount: 0,//当日轮次
                    preTurnCount: 0,//预购伦茨
                    preMoneyCount: 0,//预购金额
                    preHourCount: 0,//预购时长
                    total: 0,//当日总数
                    trained: 0,//已培训
                    UnTrain: 0,//未培训
                    trainning: 0,//正在培训
                    totalCost: 0,//当日收款
                    vipCount: 0,//获取VIP申请未处理的数量
                    AbnormalCount: 0,//业务异常申请数量
                    busAbnormalCount: 0,//业务对比异常
                    schAbnormalCount: 0,//本校对比异常
                    IntegrationByDay: {}
                },
                inDayechart: {
                    option: {
                        title: {
                            text: '预购情况',
                            subtext: ''
                        },
                        tooltip: {
                            trigger: 'axis'
                        },
                        legend: {
                            data: ['大车', '小车']
                        },
                        toolbox: {
                            show: true,
                            feature: {
                                mark: { show: true },
                                magicType: { show: true, type: ['bar', 'line'] },
                                restore: { show: true },
                                saveAsImage: { show: true }
                            }
                        },
                        calculable: true,
                        xAxis: [
                            {
                                type: 'category',
                                // boundaryGap: false,
                                data: [
                                this.$moment().subtract(-1, 'days').format('MM-DD'),
                                this.$moment().subtract(-2, 'days').format('MM-DD'),
                                this.$moment().subtract(-3, 'days').format('MM-DD'),
                                this.$moment().subtract(-4, 'days').format('MM-DD'),
                                this.$moment().subtract(-5, 'days').format('MM-DD'),
                                this.$moment().subtract(-6, 'days').format('MM-DD'),
                                this.$moment().subtract(-7, 'days').format('MM-DD'),]
                            }
                        ],
                        yAxis: [
                            {
                                type: 'value',
                                axisLabel: {
                                    formatter: '{value}'
                                }
                            }
                        ],
                        series: [
                            {
                                name: '大车',
                                type: 'bar',
                                data: [],
                                itemStyle: {
                                    normal: {
                                        color: '#32DAC3',

                                    }
                                },
                                markPoint: {
                                    data: [
                                        { type: 'max', name: '最大值' },
                                        { type: 'min', name: '最小值' }
                                    ]
                                },
                                markBar: {
                                    data: [
                                        { type: 'average', name: '平均值' }
                                    ]
                                }
                            },
                            {
                                name: '小车',
                                type: 'bar',
                                data: [],
                                itemStyle: {
                                    normal: {
                                        color: '#07A7E3',

                                    }
                                },
                                markPoint: {
                                    data: [
                                        { name: '周最低', value: -2, xAxis: 1, yAxis: -1.5 }
                                    ]
                                },
                                markBar: {
                                    data: [
                                        { type: 'average', name: '平均值' }
                                    ]
                                }
                            }
                        ]
                    }
                }
            },
            timeOut:{
                times:0
            },
            modifyPwd: {
                visible: false,
                userId: sessionStorage.getItem('userId'),
                userName: '',
                systemName: '',
            }
        }
    },
    methods: {
        handleReceive() {
            this.modifyPwd.visible = false;
        },
        getturnCount() {
            let query = this.homePage.list.query;
            getturnCount(query).then( res => {
                this.homePage.statis.preTurnCount = res.data.totalCnt;
                this.homePage.statis.preMoneyCount = res.data.totalCost;
                this.homePage.statis.preHourCount = res.data.totalTime;
                this.getTodayIntegration();
            });
        },
        // 查询当天租车情况，包括购买总数、已训、未训、正在培训(不包括注销)
        getTodayIntegration() {
            let query = this.homePage.list.query;
            getTodayIntegration(query).then( res => {
                this.homePage.statis.total = res.data.total?res.data.total:0;
                this.homePage.statis.UnTrain = res.data.UnTrain? res.data.UnTrain:0;
                this.homePage.statis.totalCost = res.data.totalCost? res.data.totalCost:0;
                this.homePage.statis.trained = res.data.trained?res.data.trained:0;
                this.homePage.statis.trainning = res.data.trainning?res.data.trainning:0;
                this.getApplyByVipCount();
            });
        },
        // 获取VIP申请未处理的数量
        getApplyByVipCount() {
            let query = this.homePage.list.query;
            getApplyByVipCount(query).then( res => {
                if(!res.result){
                    this.homePage.statis.vipCount = res.data.total;
                }
                this.getApplyByAbnormalCount();
            });
        },
        //获取最近一周未处理的业务异常申请数量
        getApplyByAbnormalCount() {
            let query = this.homePage.list.query;
            getApplyByAbnormalCount(query).then( res => {
                this.homePage.statis.AbnormalCount = res.data.total;
                this.getFurtureIntegrationByDay();
            });
        },
        //获取预购轮次的最近一周的大小车轮次情况
        getFurtureIntegrationByDay() {
            let query = this.homePage.list.query;
               getFurtureIntegrationByDay(query).then( res => {
                this.PhotoAbnormalListCount();
                // this.getLocalTrainAbnormalListCount2();
                this.homePage.statis.IntegrationByDay = res.data;
                let bigArr = [0,0,0,0,0,0,0];
                let smallArr = [0,0,0,0,0,0,0];
                Object.keys(res.data).forEach( key => {
                    if (key.indexOf('big') !== -1) {
                        let index = key.substr(key.indexOf('y')+1,1) -1;
                        bigArr.splice(index, 1, res.data[key]);
                    } else {
                        if (key.indexOf('today') === -1) {
                            let index = key.substr(key.indexOf('y')+1,1) -1;
                            smallArr.splice(index, 1, res.data[key])
                        }
                    }
                });
                // console.log(bigArr,smallArr)
                this.homePage.inDayechart.option.series[0].data = bigArr;
                this.homePage.inDayechart.option.series[1].data = smallArr;
                this.creawPre();
            });
        },
        // 业务对比异常
        PhotoAbnormalListCount() {
            let params = { orderDate: this.$moment().add(-1, "days").format('YYYYMMDD'), orderDateEnd: this.$moment().format('YYYYMMDD'), readTag: -1, unitId: sessionStorage.getItem('unitId'), stage: 0, vehType: "", veh: "" }
            PhotoAbnormalListCount(params).then( res => {
                    this.homePage.statis.busAbnormalCount = res.data.rowCount;
            })
        },
        // 本校对比异常
        getLocalTrainAbnormalListCount2(){
            let params = { begin: this.$moment().add(-1, "days").format('YYYYMMDD'), end: this.$moment().format('YYYYMMDD'), readTag: -1, unitId: sessionStorage.getItem('unitId')}
            getLocalTrainAbnormalListCount2(params).then( res => {
                this.PhotoAbnormalListCount();
                this.homePage.statis.schAbnormalCount = res.data.cnt;
            })
        },
        // 绘制预购情况
        creawPre() {
            this.$nextTick(()=>{
                if(document.getElementById('echar')){
                    let myChart = this.$echarts.init(document.getElementById('echar'));
                    myChart.showLoading();
                    window.onresize = myChart.resize;
                    myChart.setOption(this.homePage.inDayechart.option);
                    myChart.hideLoading();
                }
            });
        },
        // 侧边提示密码
        openNotification (type) {
            this.$notification[type]({
              message: '密码安全提示',
              description: '您的密码过于简单,应网络主管部门要求，必须修改为复杂密码!',
              duration:null,
              placement: 'bottomRight',
            });
          },
    },
    created() {

    },
    mounted() {
        $('.ant-layout-content').css({'backgroundColor':'#f0f0f0'});
        this.$notification.destroy()
        this.getturnCount();
        this.timeOut.times = setInterval(this.getturnCount,900000);
        this.creawPre();
        // 校验密码强度，不符合强密码规格自动打开修改密码界面
        var pwdRegex = /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\\W_!@$%\^\-_=+\[\{\}\]\:,.\/\?~#\*]+$)(?![a-z0-9]+$)(?![a-z\\W_!@$%\^\-_=+\[\{\}\]\:,.\/\?~#\*]+$)(?![0-9\\W_!@$%\^\-_=+\[\{\}\]\:,.\/\?~#\*]+$)[a-zA-Z0-9\\W_!@$%\^\-_=+\[\{\}\]\:,.\/\?~#\*]{8,26}$/
        if (!pwdRegex.test(sessionStorage.getItem("password"))) { 
            this.modifyPwd.visible = true
            this.openNotification("warning");
        }
        // let password =sessionStorage.getItem("password")?sessionStorage.getItem("password").length:0;
        // if(password <= 6){
        //     this.openNotification("warning");
        // }
    },
    activated() {
        this.creawPre();
    },
    computed: {
        ...mapGetters(['getNews','getAbnormalNum']),
      },
    destroyed(){
        $('.ant-layout-content').css({'backgroundColor':'#fff'});
        clearInterval(this.timeOut.times);
    }
};
